import React from 'react';

export function CardPriceConstructor({ item }) {
  if (item.soldout) {
    return <div className='card-price'>SOLD OUT</div>;
  }

  if (item.category === 'SOON') {
    return <div className='card-price'>PRE-ORDER NOW</div>;
  }

  if (item.price_old) {
    const discount = Math.ceil((item.price / item.price_old) * 100 - 100);

    return (
      <>
        <div className='card-price-old'>{item.price_old}</div>
        <div className='card-new-price-info'>
          <div className='card-price-new'>{item.price} RUB</div>
          <div className='card-discount'>{discount}%</div>
        </div>
      </>
    );
  }

  return <div className='card-price'>{item.price} RUB</div>;
}
