import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { CardPriceConstructor } from './CardPriceConstructor';

export default function Card({ item, id }) {
  const Navigate = useNavigate();
  const [hover, setHover] = useState(false);

  const openModal = () => {
    Navigate(`/home/card/${id}`);
  };

  const openEditMode = () => {
    Navigate(`/home/change/card/${id}`);
  };

  return (
    <div
      className='col-6 col-md-3'
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {item.soldout && <div className='sold-out'>SOLD OUT</div>}
      <div className='photo-block'>
        <LazyLoadImage
          src={hover ? item.backphoto : item.mainphoto}
          alt={item.name}
          effect='blur'
          threshold={100}
          className='card-photo'
          onClick={() => openModal()}
        />
      </div>
      <div className='card-details'>
        <div className='card-title'>{item.name}</div>
        <CardPriceConstructor item={item} />

        <button className='edit-pencil' onClick={openEditMode}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width=''
            height='100%'
            fill='currentColor'
            className='bi bi-pencil-square'
            viewBox='0 0 16 16'
          >
            <path d='M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z' />
            <path
              fillRule='evenodd'
              d='M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z'
            />
          </svg>
        </button>
      </div>
    </div>
  );
}
